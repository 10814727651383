import React ,{useState} from 'react'
import ReactDom from 'react-dom'
import '../Layout/Slider.css'
import BtnSlider from './BtnSlider'
import closebtnimg from '../assest/close.png'

const MODAL_STYLES={
    position:'fixed',
    top:"45%",
    left:'50%',
    transform:'translate(-50%,-50%)',
    backgroundcolor:'#FFF',
    padding:'5%',
    width: '100vw',
    height:"100vh",
    zIndex:1000
}

const OVERLAY_STYLE={
    position:'fixed',
    top:0,
    left:0,
    right:0,
    bottom:0,
    backgroundColor:'rgba(0,0,0,.7)',
    zIndex:1000

}

export default function Modal({open,children, onClose,path}) {
    const [slideIndex,setSlideIndex]=useState(1);
    const [left,setLeft]=useState(true);
    const nextSlide=()=>{
        setLeft(true);
        if(slideIndex !== children.length){
            setSlideIndex(slideIndex+1)
        }
        else if(slideIndex ===children.length){
            setSlideIndex(1);
        }
    }

    const prevSlide=()=>{
        setLeft(false);
        if(slideIndex !== 1){
            setSlideIndex(slideIndex-1)
        }
        else if(slideIndex ===1){
            setSlideIndex(5);
        }
    }

    const close=()=>{
        setSlideIndex(1);
        onClose();
    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    if(!open) return null;

    return ReactDom.createPortal(
        <>
            <div style = {OVERLAY_STYLE}></div>
                <div className='modal' style = {MODAL_STYLES}>
                    <img src={closebtnimg} className = 'close-btn' onClick={close}/>
                        <div className="container-slider">
                    {children.map((obj, index) => {
                        return (
                            <div
                            key={obj.id}
                            className={slideIndex === index + 1 ? 
                                left?"slide active-left-anim" :
                                "slide active-right-anim"
                            
                                : "slide"}
                            >
                                <img 
                                src={"https://atechsolutions.com.hk/"+path+obj.image}
                                />
                            </div>
                        )
                    })}
                    {/* <BtnSlider moveSlide={nextSlide} direction={"next"} />
                    <BtnSlider moveSlide={prevSlide} direction={"prev"}/> */}

                    {/* <div className="container-dots">
                        {Array.from({length: children.length}).map((item, index) => (
                            <div 
                            onClick={() => moveDot(index + 1)}
                            className={slideIndex === index + 1 ? "dot active" : "dot"}
                            ></div>
                        ))}
                    </div> */}
                </div>
            </div>
        </>,
        document.getElementById('portal')
    )//
}

// add data at resumeData-en.json
//