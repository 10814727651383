import React, { Component ,useState} from "react";

import Fade from "react-reveal";
import Modal from './Modal'

let id = 0;
export default function Portfolio ({data}) {
  const[isOpen,setIsOpen]=useState(false);
  const[picFile,setPicFile] = useState();
  const[albumPath,setAlbumPath] = useState();
  
  async function toggleGalery(open,pics,path){
    await setAlbumPath(path)
    await setPicFile(pics);
    setIsOpen(open);
  }
    if (!data) return null;
    const albums = data.albums.map(function (album,index) {
      index+=1;
      let albumCover = "images/" + album.title +"/01.jpg";
      return (
        <div key={id++} className="columns portfolio-item">
          <div className="item-wrap">
            <img className="album" alt={album.title} src={albumCover} onClick={()=>toggleGalery(true,album.pics,"images/"+album.title+"/")}/>
            <div style={{ textAlign: "center" }}>{album.description}</div>
          </div> 
        </div>
      );
    });

    return (
      <section id="portfolio">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>Check Out Some of Our Works</h1>
              <div
                id="portfolio-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {albums}
              </div>
            </div>
          </div>
        </Fade>
        <Modal open={isOpen} onClose={()=>setIsOpen(false)} path={albumPath}>
              {picFile}
        </Modal>
      </section>
    );
  }

