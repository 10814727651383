import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "../App.css";
import Header from "./Header";
import About from "./About";
import Resume from "./Resume";
import Contact from "./Contact";
import Portfolio from "./Portfolio";

class Home extends Component {

  constructor(props) {
    const options = [
      'English', '繁體中文', '简体中文'
    ];

    const defaultOption = options[0];
    super(props);
    this.state = {
      foo: "bar",
      language:"en",
      resumeData: {}
    };
    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);

  }


  getResumeData() {
    $.ajax({
      url: "../resumeData-en.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  resetLanguage(language){
    this.appFunc(language);
  }

  appFunc(language){
    let txtsrc = "../resumeData-"+ language+".json";
    $.ajax({
      url: txtsrc,
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
    this.render();
  }

  render() {

    return (

        <div className="App">
          <Header data={this.state.resumeData.main} appFunction={(language)=>this.resetLanguage(language)}/>
          <About data={this.state.resumeData.main} />
          <Resume data={this.state.resumeData.resume} />
          <Portfolio data={this.state.resumeData.portfolio} />
          <Contact data={this.state.resumeData.main} />
        </div>

    );
  }
}

export default Home;
