import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "../App.css";
import HeaderContact from "./HeaderNew";
import About from "./About";
import Resume from "./Resume";
import Contact from "./Contact";
import Portfolio from "./Portfolio";

class Offer extends Component {

  constructor(props) {
    const options = [
      'English', '繁體中文', '简体中文'
    ];

    const defaultOption = options[0];
    super(props);
    this.state = {
      foo: "bar",
      language:"en",
      resumeData: {}
    };
    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);

  }


  getResumeData() {
    $.ajax({
      url: "../resumeData-en.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  resetLanguage(language){
    this.appFunc(language);
  }

  appFunc(language){
    let txtsrc = "../resumeData-"+ language+".json";
    $.ajax({
      url: txtsrc,
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
    this.render();
  }

  render() {

    return (

        <div className="overview">

          <HeaderContact data={this.state.resumeData.main} appFunction={(language)=>this.resetLanguage(language)}/>

            <section id="aeim">

              <div className="row">
                
                  <h1 class="heading_normal">Atech Enterprise Information Manager</h1>

              </div>

              <Contact data={this.state.resumeData.main} />

          </section>


        {/* <div class="reversedBg">
          <section class="pr_section feature_wrapper">
          <div class="content_inner">
            <div class="l_content_read u_align_left u_spacing_mt_xsmall align_center">
              <p class="u_align_left_sp align_center mB70">以下是AEIM的主要特色：</p>
            </div>

            <ul class="function_icon_list">
              <li>
                <p class="function_icon_img"><img src="images/functions-01.png" width="100%" alt="Productivity"/></p>
                <dl class="function_icon_detail">
                  <dt>提升生產力</dt>
                  <dd>簡化文件處理流程以減少人為錯誤。</dd>
                </dl>
              </li>
              <li>
                <p class="function_icon_img"><img src="images/functions-02.png" width="100%" alt="Digitalization"/></p>
                <dl class="function_icon_detail">
                  <dt>文件數位化</dt>
                  <dd>自動化流程快速歸檔，並完整保存使用紀錄。</dd>
                </dl>
              </li>
            </ul>
            <ul class="function_icon_list">
              <li>
                <p class="function_icon_img"><img src="images/functions-03.png" width="100%" alt="Flexibility"/></p>
                <dl class="function_icon_detail">
                  <dt>系統靈活性</dt>
                  <dd>具備系統整合擴充性，能針對使用者、部門或企業特定需求進行客製化功能對應。</dd>
                </dl>
              </li>
              <li>
                <p class="function_icon_img"><img src="images/functions-04.png" width="100%" alt="Transparency"/></p>
                <dl class="function_icon_detail">
                  <dt>進度透明化</dt>
                  <dd>自動化流程讓工作狀況一目瞭然，輕鬆掌握使用紀錄。</dd>
                </dl>
              </li>
            </ul>
            <ul class="function_icon_list">
              <li>
                <p class="function_icon_img"><img src="images/functions-05.png" width="100%" alt="User Experience"/></p>
                <dl class="function_icon_detail">
                  <dt>直覺式操作</dt>
                  <dd>採用人性化設計，直覺式的操作，減輕使用者操作負擔。</dd>
                </dl>
              </li>
              <li>
                <p class="function_icon_img"><img src="images/functions-06.png" width="100%" alt="Collaboration"/></p>
                <dl class="function_icon_detail">
                  <dt>團隊合作</dt>
                  <dd>每個人、每個部門、每個單位都能在同一個平台上共同作業，進行溝通和審查資訊。</dd>
                </dl>
              </li>
            </ul>
            <ul class="function_icon_list">
              <li>
                <p class="function_icon_img"><img src="images/functions-07.png" width="100%" alt="Security"/></p>
                <dl class="function_icon_detail">
                  <dt>資料安全性</dt>
                  <dd>經由加密的安全平台，所有數據可以根據複雜的角色職權進行讀取權限設定。</dd>
                </dl>
              </li>
              <li>
                <p class="function_icon_img"><img src="images/functions-08.png" width="100%" alt="Compliance"/></p>
                <dl class="function_icon_detail">
                  <dt>全球適用</dt>
                  <dd>系統設計符合世界各地的制度規章。</dd>
                </dl>
              </li>
            </ul>
          </div>
          </section>
        </div>
 */}

        </div>

    );
  }
}

export default Offer;
