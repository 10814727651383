import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";
import Dropdown from 'react-dropdown';

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const name1 = this.props.data.name1;
    const name2= this.props.data.name2;
    const description = this.props.data.description;
    const profilepic = "images/" + this.props.data.icon;

    const homeTitle = this.props.data.homeTitle;
    const aboutTitle = this.props.data.aboutTitle;
    const serviceTitle = this.props.data.serviceTitle;
    const worksTitle = this.props.data.worksTitle;
    const contactTitle = this.props.data.contactTitle;

    
    const options = [
      'English', '繁體中文', '简体中文'
    ];

    const defaultOption = options[0];

    const scrollToTop = () =>{
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const _onSelect =(e)=> {
      let language="";
      switch (e.value) {
        case '繁體中文':
          language="zh"
          break;
        case '简体中文':
          language="cn"
          break;
        default:
          language="en"
      }
      this.props.appFunction(language);
    }

    return (
      <header id="home">
        
        <ParticlesBg type="square" bg={true} />
        <nav id="nav-wrap">
          <b onClick={scrollToTop} >
                <img
                  className="icon"
                  src={profilepic}
                  href="#home"
                  alt="Atech Logo"
                   />
          </b>
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Show navigation
          </a>
          <ul id="nav" className="nav" >
            <li className="current">
              <a className="smoothscroll" href="#home">
                {homeTitle}
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#about">
                {aboutTitle}
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#services">
                {serviceTitle}
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#portfolio">
                {worksTitle}
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contact">
                {contactTitle}
              </a>
            </li>
          </ul> 
        </nav>

        <div className="row banner">
          <div className="banner-text">

            <Fade bottom style={{ flexDirection: 'row' }}>
              <h1 className="responsive-headline" style={{ flexShrink: 1 }}>{name}</h1>
              <h1 className="responsive-headline" style={{ flexShrink: 1 }}>{name1}</h1>
              <h1 className="responsive-headline" style={{ flexShrink: 1 }}>{name2}</h1>
            </Fade>

            <Fade bottom duration={1200}>
              <h3>{description}</h3>
            </Fade>

            <Fade bottom duration={2000}>
              <h3>Atech Enterprise Information Manager</h3>
              <h4><a href="/offer">Click for details ></a></h4>
            </Fade>

          </div>
        </div>

      </header>
    );
    
  }
}

export default Header;

/* change language dropdown list
<div className="language">
          <Dropdown menuPlacement="bottom" 
                    options={options} 
                    onChange={_onSelect} 
                    value={defaultOption} />
        </div>*/
