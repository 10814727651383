import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class ContactNew extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const message = this.props.data.contactmessage;
    
    const nTit =this.props.data.nameL;
    const eTit = this.props.data.emailL;
    const pTit = this.props.data.phoneL;
    const mTit = this.props.data.messageL;
    const sTit = this.props.data.submitL;

    const firstName1 =this.props.data.firstName1;
    const lastName1 =this.props.data.lastName1;
    const email1 =this.props.data.email1;
    const contactNo1 =this.props.data.contactNo1;
    const companyName1 =this.props.data.companyName1;
    const position1 =this.props.data.position1;
    const businessNature1 =this.props.data.businessNature1;
    const address1 =this.props.data.address1;
    const commMethod1 =this.props.data.commMethod1;
    const message1 =this.props.data.message1;
    const submit1 =this.props.data.submit1;

    const message2 = "我想了解關於ERP/CRM/POS/HCM/eCommerce/APP/RPA/AI，詳情如下:";

    return (
      <section id="contactNew">
        {/* <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">{message}</p>
            </div>
          </div>
        </Fade> */}

        {/* <div className="row"> */}
          <Slide right duration={1000}>
            <div className="columns">

              {/* <img src="images/offer_banner.png" alt="文件數碼化＋ECM系統" title="" />
              <br /><br /> */}


              <div>馬上行動，填妥以下資料，了解最新ERP企業管理系統或其他客制程式開發!</div>

              <br></br>

              <form action="" method="post" id="contactFormNew" name="contactFormNew">
                <fieldset>

                  <div>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="firstName1"
                      name="firstName1"
                      placeholder={firstName1}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="lastName1"
                      name="lastName1"
                      placeholder={lastName1}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="email1"
                      name="email1"
                      placeholder={email1}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactNo1"
                      name="contactNo1"
                      placeholder={contactNo1}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="companyName1"
                      name="companyName1"
                      placeholder={companyName1}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="position1"
                      name="position1"
                      placeholder={position1}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>

                    <select id="businessNature1">
                      <option disabled="" value="">公司業務性質*</option>
                      <option value="Accounting / Company Secretary">會計/公司秘書</option>
                      <option value="Ad / Mktg and PR, Design and Media">廣告/市場推廣及公關/設計及媒體宣傳</option>
                      <option value="Architect / Engineering / Construction">建築/工程/建造</option>
                      <option value="Catering">飲食</option>
                      <option value="Education">教育</option>
                      <option value="Fashion / Garment / Textile / Woven Label">時裝/成衣/紡織/織嘜</option>
                      <option value="Finance / Bank / Insurance">金融/銀行/保險</option>
                      <option value="Government Department / Public Utilities">政府部門/公共服務</option>
                      <option value="Hotel">酒店</option><option value="IT / Computer">資訊科技/電腦</option>
                      <option value="Law Firm">法律</option><option value="Logistics / Shipping / Forwarder">物流/船務/貨運</option>
                      <option value="Label Printing">標籤</option>
                      <option value="Newspaper / Magazine / Publications">報紙/雜誌/出版</option>
                      <option value="NGO / Charity">非牟利機構/慈善</option>
                      <option value="Output, Offset and Commercial Printer">商業印刷/分色及打稿服務</option>
                      <option value="Religious / Church">宗教團體</option>
                      <option value="Real Estate / Property Management">房屋地產/物業管理</option>
                      <option value="Telecommunications / Network">電訊/網絡</option>
                      <option value="Travel Agency">旅遊</option><option value="Others">其他</option>
                    </select>                    

                  </div>

                  <div>
                    <select id="address1">
                      <option disabled="" value="">公司地區*</option>
                      <option value="Aberdeen">香港仔</option>
                      <option value="Admiralty">金鐘</option>
                      <option value="Ap Lei Chau">鴨脷洲</option>
                      <option value="Causeway Bay">銅鑼灣</option>
                      <option value="Central">中環</option>
                      <option value="Central And Western">中西區</option>
                      <option value="Chaiwan">柴灣</option>
                      <option value="Cheung Chau">長洲</option>
                      <option value="Cheung Sha Wan">長沙灣</option>
                      <option value="Choi Hung">彩虹</option>
                      <option value="Diamond Hill">鑽石山</option>
                      <option value="Discovery Bay">愉景灣</option>
                      <option value="Fanling">粉嶺</option>
                      <option value="Fo Tan">火炭</option>
                      <option value="Fortress Hill">炮台山</option>
                      <option value="Happy Valley">跑馬地</option>
                      <option value="Heng Fa Chuen">杏花村</option>
                      <option value="Ho Man Tin">何文田</option>
                      <option value="Hung Hom">紅磡</option>
                      <option value="Jordan">佐敦</option>
                      <option value="Kam Tin">錦田</option>
                      <option value="Kowloon Bay">九龍灣</option>
                      <option value="Kowloon City">九龍城</option>
                      <option value="Kowloon Tong">九龍塘</option>
                      <option value="Kwai Chung">葵涌</option>
                      <option value="Kwun Tong">觀塘</option>
                      <option value="Lai Chi Kok">荔枝角</option>
                      <option value="Lam Tin">藍田</option>
                      <option value="Lamma">南丫島</option>
                      <option value="Lantau">大嶼山</option>
                      <option value="Lok Fu">樂富</option>
                      <option value="Lok Ma Chau">落馬洲</option>
                      <option value="Ma On Shan">馬鞍山</option>
                      <option value="Ma Wan">馬灣</option>
                      <option value="Mei Foo">美孚</option>
                      <option value="Mid Levels">半山</option>
                      <option value="Mong Kok">旺角</option>
                      <option value="Ngau Tau Kok">牛頭角</option>
                      <option value="North Point">北角</option>
                      <option value="Pat Heung North">八鄉北</option>
                      <option value="Pat Heung South">八鄉南</option>
                      <option value="Peng Chau">坪洲</option>
                      <option value="Pokfulam">薄扶林</option>
                      <option value="Prince Edward">太子</option>
                      <option value="Quarry Bay">鰂魚涌</option>
                      <option value="Sai Kung">西貢</option>
                      <option value="Sai Wan Ho">西灣河</option>
                      <option value="San Po Kok">新蒲崗</option>
                      <option value="Sha Tin">新田</option>
                      <option value="Sha Tau Kok">沙頭角</option>
                      <option value="Sham Shui Po">深水埗</option>
                      <option value="Shaukeiwan">筲箕灣</option>
                      <option value="Shek Kip Mei">石硤尾</option>
                      <option value="Shek Kwu Chau">石鼓洲</option>
                      <option value="Shek Lei">石籬</option>
                      <option value="Shek Yam">石蔭</option>
                      <option value="Shenzhen Bay">深圳灣</option>
                      <option value="Sheung Shui">上水</option>
                      <option value="Sheung Wan">上環</option>
                      <option value="Southern District">南區</option>
                      <option value="Tai Kok Tsui">大角咀</option>
                      <option value="Tai Koo Shing">太古城</option>
                      <option value="Tai Po">大埔</option>
                      <option value="Tai Wai">大圍</option>
                      <option value="Tai Wo">大窩口</option>
                      <option value="Tin Hau">天后</option>
                      <option value="Tin Shui Wai">天水圍</option>
                      <option value="To Kwa Wai">土瓜灣</option>
                      <option value="Tseung Kwan O">將軍澳</option>
                      <option value="Tsim Sha Tsui">尖沙咀</option>
                      <option value="Tsing Yi">青衣</option>
                      <option value="Tsuen Wan">荃灣</option>
                      <option value="Tsz Wan Shan">慈雲山</option>
                      <option value="Tuen Mun">屯門</option>
                      <option value="Tung Chung">東涌</option>
                      <option value="Wanchai">灣仔</option>
                      <option value="West Point">西區</option>
                      <option value="Wong Tai Sin">黃大仙</option>
                      <option value="Yau Tong">油塘</option>
                      <option value="Yaumatei">油麻地</option>
                      <option value="Yuen Long">元朗</option>
                      <option value="Macau">澳門</option>
                      <option value="Other">其他</option>
                    </select>                    

                  </div>

                  <div>

                    <select id="commMethod1">
                      <option disabled="" value="">通訊方法</option>
                      <option value="Email and Phone">電郵及電話 Email and Phone</option>
                      <option value="Email only">僅電郵 Email only</option>
                      <option value="Phone only">僅電話 Phone only</option>
                    </select>

                  </div>

                  <div>
                    <textarea
                      cols="40"
                      rows="3"
                      id="message1"
                      name="message1"
                      defaultValue={message2}
                    ></textarea>
                  </div>

                  <div id="submit-bg">
                    <button id="submit" className="submit" >{submit1}</button>
                  </div>

                </fieldset>
              </form>

              <div id="message-sending">
                    <i className="fa fa-spinner fa-spin"></i>訊息處理中，請稍候...
              </div>

              <div id="message-success">
                    <i className="fa fa-check"></i>訊息發送成功。我們的代表會盡快與閣下聯絡。
              </div>

              <div id="message-warning">Error</div>

            </div>
          </Slide>

          {/* <Slide right duration={1000}>
            <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                <h4>Address</h4>
                <p className="address">
                  {street} <br />
                  {city}, {state} {zip}
                  <br />
                </p>
                <h4>Phone</h4>
                <p>
                  <span>{phone}</span>
                </p>
              </div>

              <div className="widget widget_tweets">
                <h4 className="widget-title">Email</h4>
                <ul id="twitter">
                  <li>
                    info@atechsolutions.com.hk
                  </li>
                  
                </ul>
              </div>

            </aside>
          </Slide> */}

        {/* </div> */}
      </section>
    );
  }
}

export default ContactNew;