import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "../App.css";
import HeaderErp from "./HeaderNew";
import About from "./About";
import Resume from "./Resume";
import Contact from "./Contact";
import Portfolio from "./Portfolio";
import ContactNew from "./ContactNew1";

class Offer extends Component {

  constructor(props) {
    const options = [
      'English', '繁體中文', '简体中文'
    ];

    const defaultOption = options[0];
    super(props);
    this.state = {
      foo: "bar",
      language:"en",
      resumeData: {}
    };
    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);

  }


  getResumeData() {
    $.ajax({
      url: "../resumeData-en.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  resetLanguage(language){
    this.appFunc(language);
  }

  appFunc(language){
    let txtsrc = "../resumeData-"+ language+".json";
    $.ajax({
      url: txtsrc,
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
    this.render();
  }

  render() {

    return (

        <div className="offer">

          <HeaderErp data={this.state.resumeData.main} appFunction={(language)=>this.resetLanguage(language)}/>

            <section id="aeim">

              {/* <div className="row">
                  <h1 className="heading_normal">Atech Enterprise Information Manager</h1>
              </div> */}

              <br />

              <div className="row mb-3 text-center">

                <div className="col-md-8 themed-grid-col black">
                
                  <div className="row mb-3 text-center">
                    <img src="images/banner_visual2.png" className="hs-image-widget " alt="New Norm_DBA_banner" title="New Norm_DBA_banner" />
                  </div>

                  {/* <div className="row mb-3 text-center">
                  <span>我們可以幫你進行文件數碼化，讓企業保持競爭力，服務範圍包括:</span><span>◆&nbsp; 文件掃描、轉移、整理及分類，為您節省人手及提升資源分配</span><span>◆&nbsp; 建立企業內容管理(Enterprise Content Management)令工作流程自動化，提供即時資料進行最新業務分析。</span>
                  </div> */}


                  {/* <div className="row mb-3 text-center">
                  <img src="images/DBA01.webp" className="hs-image-widget " alt="New Norm_DBA_banner" title="New Norm_DBA_banner" />
                  </div>

                  <br /><br />

                  <div className="row mb-3 text-center">
                  <img src="images/DBA02.webp" className="hs-image-widget " alt="New Norm_DBA_banner" title="New Norm_DBA_banner" />
                  </div>
                
                  <br /><br /> */}

                </div>

                <div className="col-md-4 themed-grid-col"><ContactNew data={this.state.resumeData.main} /></div>

                <div className="hide"><img src="images/logo_1200x1200.png" alt="Atech Logo"/><img src="images/logo_1200x300.png" alt="Atech Logo"/></div>

              </div>

          </section>

        </div>

    );
  }
}

export default Offer;
