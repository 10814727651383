import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "../App.css";
import HeaderFunctions from "./HeaderNew";
import About from "./About";
import Resume from "./Resume";
import Contact from "./Contact";
import Portfolio from "./Portfolio";

class Functions extends Component {

  constructor(props) {
    const options = [
      'English', '繁體中文', '简体中文'
    ];

    const defaultOption = options[0];
    super(props);
    this.state = {
      foo: "bar",
      language:"en",
      resumeData: {}
    };
    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);

  }


  getResumeData() {
    $.ajax({
      url: "../resumeData-en.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  resetLanguage(language){
    this.appFunc(language);
  }

  appFunc(language){
    let txtsrc = "../resumeData-"+ language+".json";
    $.ajax({
      url: txtsrc,
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
    this.render();
  }

  render() {

    return (

        <div className="overview">

          <HeaderFunctions data={this.state.resumeData.main} appFunction={(language)=>this.resetLanguage(language)}/>





            <section id="aeim">

              <div className="row">
                
                  <h1 className="heading_normal">AEIM可以解決您在工作上的所有痛點</h1>

              </div>



              <div className="reversedBg">
          <div className="pr_section">
            <div className="content_inner">
              <div className="u_spacing_pd pT0">
                <ul className="grid_column_2col_type3 keim-feat sort_order">
                  <li>
                    <h3 className="heading_tertiary_normal">為企業量身打造，輕鬆做到靈活配置</h3>
                    <p>企業在業務繁忙的狀態下，需要處理各式各樣的文件並進行審核，是相當大的負擔。為了妥善處理每項文件，AEIM可以依據使用者的習慣設計工作流程，達到無痛轉移系統。工作流程支援電子簽章、設定代理人、寫入註解等功能，具備即時性、靈活配置、快速佈署、可擴充等特點，滿足企業不斷變化的功能需求。</p>
                  </li>
                  <li className="img-side">
                    <img src="images/img_features01.png" alt="Protect your information"/>
                  </li>
                </ul>
              </div>

              <div className="u_spacing_pd_bottom">
                <ul className="grid_column_2col_type3 keim-feat">
                  <li className="img-side">
                    <img src="images/img_features02.png" alt="Protect your information"/>
                  </li>
                  <li>
                    <h3 className="heading_tertiary_normal">值得信賴的資安守護</h3>
                    <p>傳統的歸檔方式缺乏資安意識，任何人都能輕易查看機密、敏感文件，確保文件儲存位置安全並配合權限控管顯得非常重要。AEIM將所有數據集中存放在受保護的加密平台，可以根據文件的分類管理權限，限制使用者行為，有權限的人才能檢視機密資料，避免文件轉寄外流，機密外洩，同時控管權限有效降低系統管理者負擔，進而提升文件安全性。</p>
                  </li>
                </ul>
              </div>

              <div className="u_spacing_pd">
                <ul className="grid_column_2col_type3 keim-feat sort_order">
                  <li>
                    <h3 className="heading_tertiary_normal">迅速搜尋文件</h3>
                    <p>企業需要管理各式各樣的文件：內部系統產出的資料、員工產出的資料、客戶提供的資料……等，在茫茫大海中搜尋文件非常耗時。AEIM提供多元的搜尋方式，讓每位使用者都能快速找到需要的文件，包含：內文檢索、訂閱、追蹤、增加至最愛、儲存搜尋條件等，可減少使用者搜尋資料時漫長的等待時間。縮小搜尋範圍，避免搜尋到不需要的檔案。透過多元的搜尋方式，大幅提升文件使用效率，不必再浪費時間尋找文件。</p>
                  </li>
                  <li className="img-side">
                    <img src="images/img_features03.png" alt="Protect your information"/>
                  </li>
                </ul>
              </div>

              <div className="u_spacing_pd_bottom">
                <ul className="grid_column_2col_type3 keim-feat">
                  <li className="img-side">
                    <img src="images/img_features04.png" alt="Protect your information"/>
                  </li>
                  <li>
                    <h3 className="heading_tertiary_normal">工作全天候不間斷</h3>
                    <p>受疫情影響，工作型態面臨巨大轉變，文件管理系統也需要不受工作環境限制。AEIM提供使用者透過瀏覽器登入使用，不受裝置系統限制，只要連上網路，分散在世界各地的員工就能連續執行工作流程，不受時間空間限制。跨國運作的工作流程不再擱置，工作不中斷。</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

          </section>


          <Contact data={this.state.resumeData.main} />

        </div>

    );
  }
}

export default Functions;
