import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";
import Dropdown from 'react-dropdown';
import {NavLink } from "react-router-dom";

class HeaderOffer extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const name1 = this.props.data.name1;
    const name2= this.props.data.name2;
    const description = this.props.data.description;
    const profilepic = "images/" + this.props.data.icon;

    const homeTitle = this.props.data.homeTitle;

    const aeimOverviewTitle = this.props.data.aeimOverviewTitle;
    const aeimFunctionsTitle = this.props.data.aeimFunctionsTitle;
    const aeimOfferTitle = this.props.data.aeimOfferTitle;
    const aeimContactTitle = this.props.data.aeimContactTitle;
    const aeimErpTitle= this.props.data.aeimErpTitle;
    const back = "Back";
    
    const options = [
      'English', '繁體中文', '简体中文'
    ];

    const defaultOption = options[0];


    
    const backToHome = () =>{
      window.location.href = "/";
    }

    const _onSelect =(e)=> {
      let language="";
      switch (e.value) {
        case '繁體中文':
          language="zh"
          break;
        case '简体中文':
          language="cn"
          break;
        default:
          language="en"
      }
      this.props.appFunction(language);
    }

    return (
      // <header id="home">
        
        <nav id="nav-wrap">
          <b onClick={backToHome} >
                <img
                  className="icon"
                  src={profilepic}
                  href="/"
                  alt="Atech Logo"
                   />
          </b>
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Show navigation
          </a>

          <ul id="nav" className="nav" >

            <li>
              <NavLink to="/overview">
                {aeimOverviewTitle}
              </NavLink>
            </li>

            <li>
              <NavLink to="/functions">
                {aeimFunctionsTitle}
              </NavLink>
            </li>

            <li>
              <NavLink to="/erp">
                {aeimErpTitle}
              </NavLink>
            </li>

            <li>
              <NavLink to="/offer">
                {aeimOfferTitle}
              </NavLink>
            </li>

            <li>
              <NavLink to="/contact">
                {aeimContactTitle}
              </NavLink>
            </li>

          </ul> 
          
        </nav>

      // </header>
    );
    
  }
}

export default HeaderOffer;

/* change language dropdown list
<div className="language">
          <Dropdown menuPlacement="bottom" 
                    options={options} 
                    onChange={_onSelect} 
                    value={defaultOption} />
        </div>*/
